@use '@material/button/button' as mdc-button;
@use '@material/button/button-theme' as mdc-button-theme;
@use '@material/button/button-text-theme' as mdc-button-text-theme;
@use '@material/button/button-filled-theme' as mdc-button-filled-theme;
@use '@material/button/button-protected-theme' as mdc-button-protected-theme;
@use '@material/button/button-outlined-theme' as mdc-button-outlined-theme;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '@material/elevation/elevation-theme' as mdc-elevation-theme;

@use './button-theme-private';
@use '../core/mdc-helpers/mdc-helpers';
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/typography/typography';
@use '../core/tokens/m2/mdc/button-filled' as tokens-mdc-button-filled;
@use '../core/tokens/m2/mdc/button-protected' as tokens-mdc-button-protected;

@function _on-color($theme, $palette) {
  $is-dark: inspection.get-theme-type($theme) == dark;
  @return if(mdc-helpers.variable-safe-contrast-tone($palette, $is-dark) == 'dark', #000, #fff);
}

@mixin _button-variant($color) {
  @include mdc-button-text-theme.theme((
    label-text-color: $color,
  ));
}

@mixin _outlined-button-variant($color) {
  @include mdc-button-outlined-theme.theme((
    label-text-color: $color,
  ));
}

@mixin color($theme) {
  @include mdc-helpers.using-mdc-theme($theme) {
    $is-dark: inspection.get-theme-type($theme) == dark;
    $on-surface: mdc-theme-color.prop-value(on-surface);
    $surface: mdc-theme-color.prop-value(surface);
    $disabled-ink-color: rgba($on-surface, if($is-dark, 0.5, 0.38));
    $disabled-container-color: rgba($on-surface, 0.12);
    $primary: mdc-theme-color.prop-value(primary);
    $on-primary: mdc-theme-color.prop-value(on-primary);
    $secondary: mdc-theme-color.prop-value(secondary);
    $on-secondary: mdc-theme-color.prop-value(on-secondary);
    $error: mdc-theme-color.prop-value(error);
    $on-error: mdc-theme-color.prop-value(on-error);

    .mat-mdc-button {
      &.mat-unthemed {
        @include _button-variant($on-surface);
      }

      &.mat-primary {
        @include _button-variant($primary);
      }

      &.mat-accent {
        @include _button-variant($secondary);
      }

      &.mat-warn {
        @include _button-variant($error);
      }

      @include button-theme-private.apply-disabled-style() {
        @include mdc-button-text-theme.theme((
          // We need to pass both the disabled and enabled values, because the enabled
          // ones apply to anchors while the disabled ones are for buttons.
          disabled-label-text-color: $disabled-ink-color,
          label-text-color: $disabled-ink-color
        ));
      }
    }

    .mat-mdc-outlined-button {
      @include mdc-button-outlined-theme.theme((
        outline-color: rgba(mdc-theme-color.prop-value(on-surface), 0.12)
      ));

      &.mat-unthemed {
        @include _outlined-button-variant($on-surface);
      }

      &.mat-primary {
        @include _outlined-button-variant($primary);
      }

      &.mat-accent {
        @include _outlined-button-variant($secondary);
      }

      &.mat-warn {
        @include _outlined-button-variant($error);
      }

      @include button-theme-private.apply-disabled-style() {
        @include mdc-button-outlined-theme.theme((
          // We need to pass both the disabled and enabled values, because the enabled
          // ones apply to anchors while the disabled ones are for buttons.
          label-text-color: $disabled-ink-color,
          disabled-label-text-color: $disabled-ink-color,
          outline-color: rgba($on-surface, 0.12),
          disabled-outline-color: rgba($on-surface, 0.12),
        ));
      }
    }

    // Ripple colors
    .mat-mdc-button, .mat-mdc-outlined-button {
      @include button-theme-private.ripple-theme-styles($theme, false);
    }

    .mat-mdc-raised-button, .mat-mdc-unelevated-button {
      @include button-theme-private.ripple-theme-styles($theme, true);
    }
  }

  $surface: inspection.get-theme-color($theme, background, card);
  $primary: inspection.get-theme-color($theme, primary);
  $accent: inspection.get-theme-color($theme, accent);
  $error: inspection.get-theme-color($theme, warn);

  $on-surface: _on-color($theme, $surface);
  $on-primary: _on-color($theme, $primary);
  $on-accent: _on-color($theme, $accent);
  $on-error: _on-color($theme, $error);

  .mat-mdc-unelevated-button {
    $default-color-tokens: tokens-mdc-button-filled.get-color-tokens($theme, $surface, $on-surface);
    $primary-color-tokens: tokens-mdc-button-filled.get-color-tokens($theme, $primary, $on-primary);
    $accent-color-tokens: tokens-mdc-button-filled.get-color-tokens($theme, $accent, $on-accent);
    $warn-color-tokens: tokens-mdc-button-filled.get-color-tokens($theme, $error, $on-error);

    &.mat-unthemed {
      @include mdc-button-filled-theme.theme($default-color-tokens);
    }

    &.mat-primary {
      @include mdc-button-filled-theme.theme($primary-color-tokens);
    }

    &.mat-accent {
      @include mdc-button-filled-theme.theme($accent-color-tokens);
    }

    &.mat-warn {
      @include mdc-button-filled-theme.theme($warn-color-tokens);
    }
  }

  .mat-mdc-raised-button {
    $default-color-tokens: tokens-mdc-button-protected.get-color-tokens(
      $theme,
      $surface,
      $on-surface
    );
    $primary-color-tokens: tokens-mdc-button-filled.get-color-tokens($theme, $primary, $on-primary);
    $accent-color-tokens: tokens-mdc-button-filled.get-color-tokens($theme, $accent, $on-accent);
    $warn-color-tokens: tokens-mdc-button-filled.get-color-tokens($theme, $error, $on-error);

    &.mat-unthemed {
      @include mdc-button-protected-theme.theme($default-color-tokens);
    }

    &.mat-primary {
      @include mdc-button-protected-theme.theme($primary-color-tokens);
    }

    &.mat-accent {
      @include mdc-button-protected-theme.theme($accent-color-tokens);
    }

    &.mat-warn {
      @include mdc-button-protected-theme.theme($warn-color-tokens);
    }

    // TODO(wagnermaciel): Remove this workaround when b/301126527 is resolved
    @include mdc-helpers.disable-mdc-fallback-declarations {
      @include mdc-elevation-theme.elevation(2);

      &:hover, &:focus {
        @include mdc-elevation-theme.elevation(4);
      }

      &:active, &:focus:active {
        @include mdc-elevation-theme.elevation(8);
      }
    }
  }

  $is-dark: inspection.get-theme-type($theme) == dark;
  $disabled-ink-color: rgba($on-surface, if($is-dark, 0.5, 0.38));
  $disabled-container-color: rgba($on-surface, 0.12);

  .mat-mdc-raised-button {
    @include button-theme-private.apply-disabled-style() {
      @include mdc-elevation-theme.elevation(0);
      @include mdc-button-protected-theme.theme((
        disabled-container-color: $disabled-container-color,
        disabled-label-text-color: $disabled-ink-color,
        container-color: $disabled-container-color,
        label-text-color: $disabled-ink-color,
      ));
    }
  }

  .mat-mdc-unelevated-button {
    @include button-theme-private.apply-disabled-style() {
      @include mdc-button-filled-theme.theme((
        disabled-container-color: $disabled-container-color,
        disabled-label-text-color: $disabled-ink-color,
        container-color: $disabled-container-color,
        label-text-color: $disabled-ink-color,
      ));
    }
  }
}

@mixin typography($theme) {
  @include mdc-helpers.using-mdc-typography($theme) {
    @include mdc-button.without-ripple($query: mdc-helpers.$mdc-typography-styles-query);
  }
}

@mixin density($theme) {
  $density-scale: theming.clamp-density(inspection.get-theme-density($theme), -3);

  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button {
    // Use `mat-mdc-button-base` to increase the specificity over the button's structural styles.
    &.mat-mdc-button-base {
      @include mdc-button-theme.density($density-scale, $query: mdc-helpers.$mdc-base-styles-query);
      @include button-theme-private.touch-target-density($density-scale);
    }
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-button') {
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}
