@use 'sass:math';
@use '@material/density/functions' as mdc-density-functions;
@use '@material/icon-button/icon-button-theme' as mdc-icon-button-theme;
@use '@material/theme/theme-color' as mdc-theme-color;
@use '../core/tokens/m2/mdc/icon-button' as tokens-mdc-icon-button;

@use './button-theme-private';
@use '../core/theming/theming';
@use '../core/theming/inspection';

$_icon-size: 24px;

@mixin _ripple-color($color) {
  --mat-mdc-button-persistent-ripple-color: #{$color};
  --mat-mdc-button-ripple-color: #{rgba($color, 0.1)};
}

@function _variable-safe-contrast-tone($value, $is-dark) {
  @if ($value == 'dark' or $value == 'light' or type-of($value) == 'color') {
    @return mdc-theme-color.contrast-tone($value);
  }

  @return if($is-dark, 'light', 'dark');
}

@mixin color($theme) {
  $color-tokens: tokens-mdc-icon-button.get-color-tokens($theme);
  $surface: inspection.get-theme-color($theme, background, card);
  $is-dark: inspection.get-theme-type($theme) == dark;
  $on-surface: if(_variable-safe-contrast-tone($surface, $is-dark) == 'dark', #000, #fff);

  .mat-mdc-icon-button {
    @include button-theme-private.ripple-theme-styles($theme, false);
    @include mdc-icon-button-theme.theme($color-tokens);
    @include _ripple-color($on-surface);

    &.mat-primary {
      $color: inspection.get-theme-color($theme, primary);
      @include mdc-icon-button-theme.theme((icon-color: $color));
      @include _ripple-color($color);
    }

    &.mat-accent {
      $color: inspection.get-theme-color($theme, accent);
      @include mdc-icon-button-theme.theme((icon-color: $color));
      @include _ripple-color($color);
    }

    &.mat-warn {
      $color: inspection.get-theme-color($theme, warn);
      @include mdc-icon-button-theme.theme((icon-color: $color));
      @include _ripple-color($color);
    }

    @include button-theme-private.apply-disabled-style() {
      $disabled-color: rgba($on-surface, if($is-dark, 0.5, 0.38));
      @include mdc-icon-button-theme.theme((
        icon-color: $disabled-color,
        disabled-icon-color: $disabled-color,
      ));
    }
  }
}

@mixin typography($theme) {
}

@mixin density($theme) {
  $density-scale: inspection.get-theme-density($theme);
  // Manually apply the expected density theming, and include the padding
  // as it was applied before
  $calculated-size: mdc-density-functions.prop-value(
    $density-config: (
      size: (
        default: 48px,
        maximum: 48px,
        minimum: 28px,
      ),
    ),
    $density-scale: $density-scale,
    $property-name: size,
  );

  // Use `mat-mdc-button-base` to increase the specificity over the button's structural styles.
  .mat-mdc-icon-button.mat-mdc-button-base {
    // Match the styles that used to be present. This is necessary for backwards
    // compat to match the previous implementations selector count (two classes).
    @include mdc-icon-button-theme.theme((
      state-layer-size: $calculated-size,
    ));

    // TODO: Switch calculated-size to "var(--mdc-icon-button-state-layer-size)"
    // Currently fails validation because the variable is "undefined"
    // in the sass stack.
    // TODO: Switch icon-size to "var(--mdc-icon-button-icon-size)". Currently
    // fails validation because the variable is "undefined" in the sass stack.
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    padding: math.div($calculated-size - $_icon-size, 2);

    @include button-theme-private.touch-target-density($density-scale);
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-icon-button') {
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}
