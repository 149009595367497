@use '../core/density/private/compatibility';
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/typography/typography';
@use './tree-variables';

@mixin color($theme) {
  .mat-tree {
    background: inspection.get-theme-color($theme, background, card);
  }

  .mat-tree-node,
  .mat-nested-tree-node {
    color: inspection.get-theme-color($theme, foreground, text);
  }
}

@mixin typography($theme) {
  // TODO(mmalerba): Stop calling this and resolve resulting screen diffs.
  $theme: inspection.private-get-typography-back-compat-theme($theme);

  .mat-tree {
    font-family: inspection.get-theme-typography($theme, body-2, font-family);
  }

  .mat-tree-node,
  .mat-nested-tree-node {
    font-weight: inspection.get-theme-typography($theme, body-2, font-weight);
    font-size: inspection.get-theme-typography($theme, body-2, font-size);
  }
}

@mixin density($theme) {
  $density-scale: inspection.get-theme-density($theme);
  $height: compatibility.private-density-prop-value(tree-variables.$density-config,
    $density-scale, height);

  @include compatibility.private-density-legacy-compatibility() {
    .mat-tree-node {
      min-height: $height;
    }
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-tree') {
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}

