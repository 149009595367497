* {
  box-sizing: border-box;
}

:root {
  background-color: #040103;
  color: #fff;
}

html,
body,
app-root {
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  font: font('body-2');
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

@for $i from 1 through 6 {
  .mat-h#{$i},
  h#{$i} {
    font: font('headline-#{$i}');
  }
}

button,
.mat-button {
  font: font('button');
}

caption,
.mat-caption {
  font: font('caption');
  color: $text;
}

.caption-color {
  color: $text;
}
