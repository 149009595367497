// Color reference
// https://www.realtimecolors.com/?colors=ffffff-040103-74ecae-031616-12ff07&fonts=SF%20Pro-SF%20Pro

@use 'sass:map';
@use '@angular/material' as mat;

$text: #9e9e9e;

/**
 * Project variables
 */
$border-color-1: rgb(69, 66, 66);
$border-color-2: rgb(125, 122, 122);

/**
 * Material related
 */

$dark-primary-text: #040103;
$light-primary-text: #fff;

$focus-color: #12ff07;

$mat-primary: (
  50: #eefdf5,
  100: #d5f9e7,
  200: #baf6d7,
  300: #9ef2c6,
  400: #89efba,
  500: #74ecae,
  600: #6ceaa7,
  700: #61e79d,
  800: #57e494,
  900: #44df84,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$mat-accent: (
  50: #e1e3e3,
  100: #b3b9b9,
  200: #818b8b,
  300: #4f5c5c,
  400: #293939,
  500: #031616,
  600: #031313,
  700: #021010,
  800: #020c0c,
  900: #010606,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$mat-grays: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #161616,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$mat-warn: mat.$red-palette;
$mat-success: mat.$green-palette;

$primary: mat.define-palette($mat-primary);
$accent: mat.define-palette($mat-accent);
$grays: mat.define-palette($mat-grays);
$warn: mat.define-palette($mat-warn);
$success: mat.define-palette($mat-success);

$ff: 'SF Pro, Tahoma, sans-serif';

$custom-typography: mat.define-typography-config(
  $font-family: $ff,
  $headline-1: mat.define-typography-level(60px, 64px, 700, $ff),
  $headline-2: mat.define-typography-level(40px, 44px, 700, $ff),
  $headline-3: mat.define-typography-level(32px, 36px, 700, $ff),
  $headline-4: mat.define-typography-level(28px, 30px, 700, $ff),
  $headline-5: mat.define-typography-level(24px, 32px, 700, $ff),
  $headline-6: mat.define-typography-level(20px, 26px, 700, $ff),
  $body-1: mat.define-typography-level(16px, 24px, 700, $ff),
  $body-2: mat.define-typography-level(16px, 24px, 400, $ff),
  $caption: mat.define-typography-level(13px, 16px, 400, $ff),
  $button: mat.define-typography-level(14px, 19px, 700, $ff)
);

$theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      grays: $grays,
      warn: $warn,
      success: $success
    ),
    typography: $custom-typography,
    density: 0
  )
);
