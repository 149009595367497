@use '@material/dialog/dialog-theme' as mdc-dialog-theme;
@use '../core/style/sass-utils';
@use '../core/tokens/m2/mdc/dialog' as tokens-mdc-dialog;
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/typography/typography';


@mixin base($theme) {
  // Add default values for tokens not related to color, typography, or density.
  @include sass-utils.current-selector-or-root() {
    @include mdc-dialog-theme.theme(tokens-mdc-dialog.get-unthemable-tokens());
  }
}

@mixin color($theme) {
  .mat-mdc-dialog-container {
    @include mdc-dialog-theme.theme(tokens-mdc-dialog.get-color-tokens($theme));
  }
}

@mixin typography($theme) {
  .mat-mdc-dialog-container {
    @include mdc-dialog-theme.theme(tokens-mdc-dialog.get-typography-tokens($theme));
  }
}

@mixin density($theme) {}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-dialog') {
    @include base($theme);
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}
